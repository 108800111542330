<template>
  <div class="flex-box vertical">
    <div class="data-title">
      <div class="title-left flex-box">
        <span>共{{pagination.total}}条记录</span>
      </div>
      <a-form-model class="query-form" layout="inline">
        <slot name="query" v-bind:form="form"></slot>
        <a-form-model-item :label="report ? '汇报时间' : '申请时间'">
          <a-range-picker
            v-model="form.time"
            :placeholder="['开始时间', '结束时间']"
            format="YYYY-MM-DD"
            value-format="YYYY-MM-DD"
            @change="getList"/>
        </a-form-model-item>
        <a-form-model-item label="状态" v-if="!report">
          <a-select
            style="width: 180px"
            placeholder="选择状态筛选"
            @change="getList"
            :options="statusList"
            v-model="form.status">
          </a-select>
        </a-form-model-item>
      </a-form-model>
      <a-space class="title-btn">
        <a-button @click="getList">查询</a-button>
        <slot name="button" v-bind:form="form"></slot>
      </a-space>
    </div>
    <div class="data-area">
      <a-table
        ref="dataTable"
        :columns="columns"
        row-key="id"
        :data-source="list"
        :loading="loading"
        :pagination="pagination"
        :scroll="{y: h}"
        @change="handleTableChange">
        <template v-slot:name="text">
          <open-data type="userName" :openid="text" />
        </template>
        <template v-slot:action="text, record">
          <div class="row-btn">
            <a class="txt-btn" @click.stop="showDetail(record)">查看详情</a>
          </div>
        </template>
      </a-table>
    </div>
  </div>
</template>

<script>

    import {dealApply, applyStatus} from "@/common/js/apply";
    import {getTemple} from "@/common/js/storage";
    export default {
        name: "ApplyList",
        props: {
            report: Boolean,
            query: String,
            showAppName: Boolean
        },
        data() {
            return {
                loading: false,
                list: [],
                form: {},
                h: 500,
                pagination: {
                    total: 0,
                    current: 1,
                    pageSize: 10,
                    showSizeChanger: true
                },
                statusList: [{key: 0, title: '全部'}].concat(applyStatus.map(s => {return {key: s.value, title: s.title}})),
                exporting: false,
            }
        },
        computed: {
            columns() {
                let record = this.report;
                let res = [];
                if(record) {
                    res = [
                        {title: '汇报时间', dataIndex: 'create_time'},
                        {title: '汇报人', dataIndex: 'user_id', scopedSlots: { customRender: 'name'}},
                        {
                            title: '汇报对象',
                            dataIndex: 'readObj',
                            customRender: text => text ? <a-space>{text.map(item => <open-data type="userName" openid={item.to_user_id} />)}</a-space> : null
                        },
                        {title: '操作', key: 'action', fixed: 'right', width: 120, scopedSlots: { customRender: 'action'}}
                    ]
                } else {
                    res = [
                        {title: '申请时间', dataIndex: 'create_time'},
                        {title: '申请人', dataIndex: 'user_id', scopedSlots: { customRender: 'name'}},
                        {title: '状态', dataIndex: 'statusText'},
                        {title: '操作', key: 'action', fixed: 'right', width: 120, scopedSlots: { customRender: 'action'}}
                    ]
                    if(this.showAppName) {
                        res.splice(1, 0, {title: '应用名称', dataIndex: 'applyTemp', customRender: text => text?.name || "--"})
                    }
                }
                return res;
            }
        },
        watch: {
            query() {
                this.$set(this.pagination, 'current', 1);
                this.getList();
            }
        },
        created() {
            this.getList();
        },
        mounted() {
            this.setHeight();
            window.addEventListener("resize", this.setHeight, false);
        },
        beforeDestroy() {
            window.removeEventListener("resize", this.setHeight, false);
        },
        methods: {
            showDetail(record) {
                this.$store.commit("setApply", {...record, report: this.report});
            },
            handleTableChange(pagination) {
                let pager = { ...this.pagination };
                pager.current = pagination.current;
                pager.pageSize = pagination.pageSize;
                this.pagination = pager;
                this.getList();
            },
            getList() {
                if(this.loading) return;
                this.loading = true;
                let report = this.report;
                let url;
                let form = this.form;
                let start, end;
                if(form.time && form.time.length == 2) {
                    start = form.time[0] + ' 00:00:00'
                    end = form.time[1] + ' 23:59:59'
                }
                if(report) {
                    url = `/admin/report?sort=-create_time&expand=readObj&filter[temple_id]=${getTemple()}`;
                    if(start && end) {
                        url += `&filter[create_time][gte]=${start}&filter[create_time][lt]=${end}`;
                    }
                } else {
                    url = `/admin/apply?sort=-id&expand=extraApply,extendApply&temple_id=${getTemple()}`;
                    if(start && end) {
                        url += `&apply_start_time=${start}&apply_end_time=${end}`;
                    }
                    if(form.status > 0) {
                        url += `&filter[status]=${form.status}`
                    }
                }
                if(form.applicant && form.applicant.length > 0) {
                    url += `&filter[user_id]=${form.applicant[0]}`;
                }
                if(form.temp_id) {
                    url += `&filter[temp_id]=${form.temp_id}`;
                }
                if(this.query) {
                    url += this.query;
                }
                let {current, pageSize} = this.pagination;
                url += `&page=${current}&pageSize=${pageSize}`;
                this.$axios({url, noTempleFilter: true}).then(res => {
                    let list = res.data;
                    if(!report) {
                        list.forEach(apply => {
                            dealApply(apply);
                        });
                    }
                    this.list = list;
                    if(res.page) {
                        this.$set(this.pagination, 'total', res.page.totalCount);
                    }
                    this.loading = false;
                }, () => {
                    this.loading = false;
                });
            },
            setHeight() {
                let dataTale = this.$refs.dataTable;
                if(dataTale) {
                    let area = this.$el.querySelector(".data-area");
                    this.h = area.clientHeight - 54 - 64;
                }
            },
        }
    }
</script>

<style scoped lang="less">

</style>

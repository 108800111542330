<template>
  <div class="apply-info">
    <div class="detail-ti" v-if="report">
      <div class="detail-ti-text"><open-data :openid="info.user_id" type="userName"></open-data>的汇报</div>
    </div>
    <a-space class="detail-ti" v-else>
      <div class="detail-ti-text">
        <open-data :openid="info.user_id" type="userName" v-if="info.user_id"></open-data>
        <span v-else-if="info.v_id && info.volunteer">{{info.volunteer.name}}</span>
        的{{info.tempName}}申请
      </div>
      <div class="detail-ti-status" :class="[`detail-status-${info.car_num_type == 2 && info.car_num_status == 2 ? 2 : info.status}`]">{{info.statusText}}</div>
    </a-space>
    <div class="detail-info">
      <div class="detail-piece">
        <div class="detail-piece-title">{{report ? '汇报详情' : '申请详情'}}</div>
        <detail-view :list="info.form" @preview="previewImg" @apply="showRelatedApply"></detail-view>
      </div>
      <div class="detail-piece" v-if="info.extraApply && info.extraApply.length > 0">
        <div class="detail-piece-title">接待需求</div>
        <div class="need-temp-list">
          <div class="piece-box need-temp-item" v-for="(item, i) in info.extraApply" :key="i">
            <div class="need-temp-info">
              <img :src="item.iconPath" class="need-temp-icon" />
              <div class="need-temp-name">{{item.name}}</div>
            </div>
            <div class="need-temp-detail">
              <detail-view :list="item.form" @preview="previewImg"></detail-view>
            </div>
          </div>
        </div>
      </div>
      <div class="detail-piece apply-process" v-if="report && info.readObj">
        <div class="report-received">
          <div class="detail-piece-title">汇报对象</div>
          <div class="report-received-list">
            <div class="report-received-item" v-for="r in info.readObj" :key="r.id">
              <open-data :openid="r.to_user_id" type="userName"/>
              <a-icon type="check-circle" class="report-received-read" v-if="r.status == 1"/>
            </div>
          </div>
        </div>
      </div>
      <div class="detail-piece assign-item" v-if="info.assignItem">
        <div class="detail-piece-title">指定{{info.assignItem.typeName}}</div>
        <div class="assign-item-select">{{info.assignItem.itemValue}}</div>
      </div>
      <div class="detail-piece apply-process" v-if="info.flow">
        <div class="detail-piece-title">审批流程</div>
        <process-list :flow="info.flow" :extra-apply="info.extraApply" @show="handleShowUserApply"></process-list>
      </div>
    </div>
  </div>
</template>

<script>
    import detailView from "../detail-view";
    import ProcessList from "../../layouts/ProcessList";

    export default {
        name: "apply-info",
        components: {
            detailView,
            ProcessList
        },
        props: {
            info: Object,
            report: Boolean
        },
        data() {
            return {
                relatedApply: null,
                loading: false,
            }
        },
        methods: {
            previewImg(file) {
                const list = this.info.imgList;
                this.$store.dispatch("showImgList", list, file.url);
            },
            handleShowUserApply(apply) {
                this.$emit("show", apply);
            },
            showRelatedApply(val) {
                if(this.loading || !val) return;
                if(this.relatedApply) {
                    this.$emit("show", this.relatedApply);
                } else {
                    this.loading = true;
                    this.$axios(`/admin/apply/${val.id}?expand=extraApply`).then(res => {
                        this.relatedApply = res;
                        this.$emit("show", this.relatedApply);
                    }).finally(() => this.loading = false);
                }
            }
        }
    }
</script>

<style lang="less">
  .apply-info {
    display: flex;
    flex-direction: column;
    .detail-info {
      flex: 1;
      overflow: auto;
      background-color: @background-color-base;
    }
  }
  .detail-ti {
    flex-shrink: 0;
    padding: 12px 24px;
    border-bottom: var(--border);
  }
  .detail-ti-text {
    font-size: 18px;
    font-weight: 700;
    text-align: center;
  }
  .detail-piece {
    padding: 10px 24px;
    background-color:  @component-background;
  }
  .detail-piece-title {
    font-weight: 600;
  }
  .detail-form {
    margin: 10px 0 0;
  }
  .detail-ti-status {
    margin-left: 6px;
    padding: 2px;
    border-radius: 2px;
    border: 1px solid;
    font-size: 12px;
    line-height: 1;
  }

  .detail-status-1 {
    border-color: #3975C6;
    color: #3975C6;
  }

  .detail-status-2 {
    border-color: #F04A3E;
    color: #F04A3E;
  }

  .detail-status-3 {
    border-color: #CCCCCC;
    color: #CCCCCC;
  }

  .detail-status-4 {
    border-color: #26BF4C;
    color: #26BF4C;
  }

  .apply-process {
    margin-top: 10px;
  }
  .assign-item {
    display: flex;
    align-items: center;
    margin-top: 10px;
  }
  .assign-item-select {
    margin-left: 12px;
  }

  .need-temp-item {
    position: relative;
    margin-top: 16px;
    padding: 6px 0;
    background-color: @component-background;
    font-size: 14px;
  }

  .need-temp-info {
    display: flex;
    align-items: center;
  }

  .need-temp-icon {
    width: 32px;
    height: 32px;
  }

  .need-temp-name {
    flex: 1;
    margin: 0 6px;
  }

  .add-need-temp {
    margin-top: 8px;
  }
  .need-temp-btn {
    padding: 3px 6px;
    font-size: 12px;
    color: #3975C6;
    cursor: pointer;
  }
  .need-temp-detail {
    margin-top: 8px;
    font-size: 12px;
  }
  .detail-operate {
    padding: 10px 0;
    border-top: var(--border);
    justify-content: center;
  }
  .report-received-list {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 10px;
    padding: 6px 24px;
  }
  .report-received-item {
    margin-right: 8px;
  }
  .report-received-read {
    margin-left: 4px;
  }
</style>

/**
 * 审批流静态配置项文件
 * 审批流json对象格式说明
 * flow: Array<node>
 *
 * 条件分支对象
 * branch: {
 *     parent: string 父节点node_id
 *     priority: number 条件分支优先级,
 *     nodes: Array<node> 分支节点数组
 * }
 *
 * 条件配置对象
 * condition: {
 *     con_id: string 条件id
 *     label: string 条件名称
 *     selectKey: string 关联的表单key
 *     type: number 条件类型 1申请人 2请假类型 3请假天数 4数字 5单选 6多选
 *     value: { 条件值
 *         key: string 关联的表单key 同外层selectKey
 *         applicant: string[] 申请人wx_user_id数组（当条件类型为申请人时）
 *         type: number[] 请假类型id数组 （当条件类型为请假类型时）
 *         type: number numberConditionOptions 请假天数或者数字比较类型（当条件类型为请假天数或者数字时）
 *         number: number 数字被比较的值 （当条件类型为数字且数字比较方式不是介于时）
 *         number: string 介于数字的区间 逗号隔开例：'1,2'表示介于 1-2之间（当条件类型为数字且数字比较方式是介于时）
 *         select：string[] 单选或多选值数组 （当条件类型为单选或多选时）
 *         type: number 多选值比较方式 1完全包括 2其一包括 （当条件类型为多选时）
 *     }
 * }
 *
 * 节点对象
 * node: {
 *     node_id: string 节点id 唯一
 *     title: string 节点名称
 *     type: string | number 节点类型 nodeRoles | 0申请人 | 'branch' 分支节点 | 'condition' 条件节点
 *     ids: string[] 节点成员wx_user_id数组
 *     leader_option: number 审批节点类型 0指定成员 1直接上级 2部门负责人 3指定标签（当节点为审批节点时） 4申请人自选 5申请人本人 6志工团身份 7堂口主管
 *     tag: object {id: number, name: string} 指定标签对象
 *     approval_type: number 多人审批方式 1会签 2或签 （当节点为审批节点时）
 *     custom_next: number 是否可以指定下一级审批节点 0否 1是 （当节点为审批节点时）
 *     apply_app: number 是否以同步发起新的应用申请 0否 1是 （当节点为审批节点时）
 *     self_cc: number 是否抄送给申请人本人  0否 1是 （当节点为抄送节点时）
 *     custom_cc: number 是否允许申请人自选抄送人  0否 1是 （当节点为抄送节点时）
 *     assign_item: string 可指定车辆/包厢 car车辆 table包厢（当节点为审批或者派单节点时）
 *     children: Array<branch> 条件分支数组 （当节点为条件分支节点时）
 *     condition: Array<condition> 条件数组 （当节点为条件节点时）
 *     elect_type: 申请人自选选人方式 1单选 2多选
 *     group_key: 关联具体的志工组选择控件key值
 *     volunteer_type: 具体志工团身份（多选 数组 例[1,2,3]）身份枚举：1组长 2值班组长 3副组长 5统计人员;
 * }
 *
 *
 */
import {clone, getKeyTitle} from "../js/tool";


export const numberConditionOptions = [
    {key: 1, title: '大于'},
    {key: 2, title: '大于等于'},
    {key: 3, title: '等于'},
    {key: 4, title: '小于'},
    {key: 5, title: '小于等于'},
    {key: 6, title: '介于'},
]

export const leaderOptions = [
    { label: '指定成员', value: 0 },
    { label: '直接上级', value: 1 },
    { label: '部门负责人', value: 2 },
    { label: '指定标签', value: 3 },
    { label: '申请人自选', value: 4 },
    { label: '申请人本人', value: 5 },
    { label: '志工团身份', value: 6 },
    { label: '堂口主管', value: 7 },
]

export const electTypeOptions = [
    { label: '单选', value: 1 },
    { label: '多选', value: 2 },
]

export const approveOptions = [
    {label: '会签（ 须所有成员同意 ）', value: 1, title: '会签'},
    {label: '或签（ 一名成员同意即可 ）', value: 2, title: '或签'},
]

export const getConditionNumberText = function (type) {
    return getKeyTitle(numberConditionOptions, type);
}
export const nodeRoles = [
    {type: 1, title: '审批人', icon: 'icon-approve', name: 'approve'},
    {type: 2, title: '抄送人', icon: 'icon-cc', name: 'cc'},
    {type: 3, title: '派单人', icon: 'icon-dispatch', name: 'dispatch'},
    {type: 4, title: '接单人', icon: 'icon-order', name: 'order'},
    {type: 5, title: '开牌人', icon: 'icon-order', name: 'tablet'},
];

export const getNodeRoleTitle = function (val) {
    if(val == 'condition') {
        return '条件分支'
    } else {
        let res = nodeRoles.find(t => t.type == val);
        return res ? res.title : null;
    }
}

export const getNodeRoleName = function (val) {
    if(val == 'condition') {
        return 'condition'
    } else {
        let res = nodeRoles.find(t => t.type == val);
        return res ? res.name : null;
    }
}

export const getElectTypeText = function (type) {
    return getKeyTitle(electTypeOptions, type, 'value', 'label');
}

export const getApproveTypeText = function (type) {
    return getKeyTitle(approveOptions, type, 'value');
}

const numberRangeItem = {
    key: 'number',
    label: '',
    component: 'number-range',
    rules: [{ required: true, message: '请设置区间', trigger: 'change' }]
}

const numberItem = {
    key: 'number',
    label: '',
    component: 'a-input-number',
    props: {
        placeholder: "请输入"
    },
    style: {
        width: '120px'
    },
    rules: [{ required: true, message: '请输入', trigger: 'blur' }]
}

export const updateNumberForm = function (val, list) {
    let item;
    if(val == 6) {
        item = clone(numberRangeItem)
    } else {
        item = clone(numberItem);
    }
    let index = list.findIndex(c => c.key == 'number');
    if(index >= 0) {
        list.splice(index, 1, item);
    } else {
        index = list.findIndex(c => c.key == 'type');
        list.splice(index + 1, 0, item);
    }
}

export const numberTypeItem = {
    key: 'type',
    label: '',
    component: 'a-select',
    props: {
        options: numberConditionOptions,
        placeholder: "请设置条件选项"
    },
    style: {
        width: '120px'
    },
    listeners: {
        change: function (val, init) {
            updateNumberForm(val, this.controls);
            // 初始化表单不清除number
            if(init !== true) {
                this.$set(this.form, 'number', undefined);
            }
        }
    },
    rules: [{ required: true, message: '请设置条件选项', trigger: 'change' }]
}


/**
 *  能作为条件选项的组件
 *  条件选项类型枚举： 1申请人 2请假类型 3请假天数 4数字 5单选 6多选
 * */
export const conditionComponents = [
    {
        component: 'input',
        type: 4,
        form: [
            numberTypeItem,
        ]
    },
    {
        component: 'select',
        type: 5,
        form: [
            {
                key: 'select',
                label: '为',
                component: 'a-checkbox-group',
                props: {
                    options: [],
                },
                rules: [{ required: true, message: '至少选择一个选项', trigger: 'change' }]
            },
        ]
    },
    {
        component: 'multiple-select',
        type: 6,
        form: [
            {
                key: 'type',
                label: '',
                component: 'a-select',
                props: {
                    options: [
                        {key: 1, title: '完全包括'},
                        {key: 2, title: '其一包括'},
                    ],
                    placeholder: "请设置条件选项"
                },
                style: {
                    width: '120px'
                },
                rules: [{ required: true, message: '请设置条件选项', trigger: 'change' }]
            },
            {
                key: 'select',
                component: 'a-checkbox-group',
                props: {
                    options: [],
                },
                rules: [{ required: true, message: '至少选择一个选项', trigger: 'change' }]
            },
        ]
    },
]

export const attendanceConditionForm = {
    leave_type: [
        {
            key: 'type',
            label: '',
            component: 'a-checkbox-group',
            props: {
                options: [],
            },
            rules: [{ required: true, message: '至少选择一个选项', trigger: 'change' }]
        },
    ],
    day_num: [
        numberTypeItem
    ]
}


export const getNodeExtraTemp = function (extraTemp) {
    extraTemp = extraTemp || [];
    return extraTemp.map(item => {
        let flowList = [];
        if(item.flowList) {
            flowList = [...item.flowList];
        } else if(item.flow) {
            try {
                flowList = JSON.parse(item.flow);
                flowList = flowList.filter(f => f.type != 0);
                item.flowList = flowList;
            } catch (e) {
                console.error(e)
            }
        }
        return {
            id: item.id,
            name: item.name,
            icon: item.icon,
            type: item.type,
            nodes: flowList
        }
    });
}



/**
 * 判断节点是否需要选择多人审批方式：审批节点成员有可能会有多个时需要选择多人审批方式
 * 1.指定成员为多个
 * 2.直接上级 部门负责人 指定标签 志工团身份 堂口主管
 * 3.申请人自选可多选时
 * @param node 节点信息
 */
export const hasApprovalType = function(node) {
    return node.type == 1 && ((node.ids && node.ids.length > 1) || ([0, 5].indexOf(node.leader_option) === -1 && node.elect_type != 1))
}

/**
 * 判断节点是否需要选择指定成员
 * 指定成员 且没有选择申请人自选或申请人本人时
 * @param node
 */
export const requireIds = function (node) {
    return node.leader_option == 0 && node.custom_cc * 1 !== 1 && node.self_cc * 1 !== 1;
}

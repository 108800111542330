<template>
  <div class="apply-process-list">
    <div class="apply-process-item" :class="{'apply-process-line': index < list.length - 1}" v-for="(item, index) in list" :key="index">
      <div class="apply-process-title">
        <div class="apply-process-icon" :class="[item.type == 'level' ? 'icon-visitors' : roleIcon[item.type]]"></div>
        <div class="apply-process-text">{{item.title}}</div>
        <template v-if="item.statusText">
          <div class="apply-dot"></div>
          <div class="apply-process-status">{{item.statusText}}</div>
        </template>
        <div class="apply-approve-type" v-if="item.type == 1 && item.ids && item.ids.length > 1">
          {{['', '会签', '或签'][item.approval_type]}}
        </div>
      </div>
      <div class="process-level" v-if="item.type == 'level'">
        <div class="process-level-temp" :class="{'process-level-show': child.open}" v-for="(child, j) in children" :key="j">
          <div class="level-temp-title" @click="openChild(child)">
            <img :src="child.iconPath" class="level-temp-icon" />
            <div class="level-temp-name">{{ child.name }}</div>
            <div class="icon-down"></div>
          </div>
          <div class="level-temp-process" v-if="child.open">
            <process-list
              v-model="child.flow"
              :approve-unchangeable="child.otherConfig.approveUnchangeable"
              :cc-un-delete="child.otherConfig.ccUnDelete"
              v-if="type == 'apply'"></process-list>
            <process-list :flow="child.flow" v-else></process-list>
          </div>
        </div>
      </div>
      <div class="process-node-book" v-else-if="type == 'apply'">
        <div class="process-node-list">
          <template v-if="item.ids && item.ids.length > 0">
            <div class="node-book-item" v-for="(id, i) in item.ids" :key="i">
              <div class="icon-user node-book-icon"></div>
              <div class="node-book-name">
                <open-data :openid="id" type="userName"></open-data>
              </div>
              <div
                class="icon-close node-remove"
                @click="removeBook(index, i)"
                v-if="(item.type == 1 && (!approveUnchangeable || item.leader_option == 4)) || (item.type == 2 && !ccUnDelete && !item.auto_book_cc)"></div>
            </div>
          </template>
          <div class="node-book-item node-book-empty" v-else-if="item.userEmpty">
            <div class="icon-user node-book-icon"></div>
            <div class="node-book-name">审批人为空</div>
            <div
              class="icon-close node-remove"
              @click="removeEmpty(index)"
              v-if="(item.type == 1 && (!approveUnchangeable || item.leader_option == 4)) || (item.type == 2 && !ccUnDelete)"></div>
          </div>
          <div class="node-book-item" v-else-if="item.leader_option == 6">
            <div class="icon-user node-book-icon"></div>
            <div class="node-book-name">{{ item.group_name + item.volunteer_type_name }}</div>
          </div>
          <div class="node-book-item" v-else-if="item.leader_option == 7">
            <div class="icon-user node-book-icon"></div>
            <div class="node-book-name">部门主管</div>
          </div>
          <div
            class="add-node-btn"
            @click="addBook(index)"
            v-if="item.custom_cc == 1 || (item.type == 1 && ((!approveUnchangeable && item.leader_option != 4) || (item.leader_option == 4 && (item.elect_type == 2 || !item.ids || item.ids.length == 0)))) || (item.type == 2 && !ccUnDelete)">
            <a-icon type="plus" />
          </div>
        </div>
        <div class="node-empty-tip" v-if="item.userEmpty">
          <div class="icon-warn"></div>
          <div class="empty-tip-text">未找到审批人，将自动同意</div>
        </div>
      </div>
      <div class="apply-process-user" v-else-if="item.userList && item.userList.length > 0">
        <div class="process-user-item" v-for="u in item.userList" :key="u.id">
          <div class="process-user-info">
            <div class="icon-user process-user-avatar">
              <div class="process-user-status-icon" :class="[u.icon]" v-if="u.status > 0"></div>
            </div>
            <div class="process-user-name">
              <open-data :openid="u.id" type="userName"></open-data>
            </div>
            <div class="process-user-state" v-if="u.status > 0">
              <div class="user-state-text">{{u.statusText}}</div>
              <div class="apply-dot"></div>
              <div class="user-state-time">{{u.time}}</div>
            </div>
          </div>
          <div class="process-user-note" v-if="u.note">“{{u.note}}”</div>
          <div class="process-user-apply" v-if="u.extendApplyList">
            <div class="need-temp-info" v-for="a in u.extendApplyList" :key="a.id">
              <img :src="a.iconPath" class="need-temp-icon">
              <div class="user-apply-temp">{{a.name}}</div>
              <div class="apply-dot"></div>
              <div class="user-state-text">{{a.statusText}}</div>
              <div class="need-temp-btn" @click="showUserApply(a)">查看详情</div>
            </div>
          </div>
        </div>
      </div>
      <div class="apply-process-user" v-else-if="item.type == 1">
        <div class="process-user-item process-user-empty">
          <div class="process-user-info">
            <div class="icon-user process-user-avatar">
              <div class="process-user-status-icon icon-success"></div>
            </div>
            <div class="process-user-name">审批人为空</div>
            <div class="process-user-state">
              <div class="user-state-text">已自动同意</div>
            </div>
          </div>
        </div>
        <div class="node-empty-tip">
          <div class="icon-warn"></div>
          <div class="empty-tip-text">未找到审批人，已自动同意</div>
        </div>
      </div>
    </div>
    <a-modal
      title="选择成员"
      :maskClosable="false"
      :closable="false"
      :width="600"
      :body-style="{padding: 0}"
      @ok="confirmSelect"
      v-model="visible">
      <dept-picker ref="bookPicker" multiple type="user" @select="handleDeptSelect"/>
    </a-modal>
  </div>
</template>

<script lang="tsx">
    import { nodeRoleIcon } from "../common/js/apply";
    import deptPicker from "../components/dept-picker.vue";

    export default {
        name: "ProcessList",
        model: {
            prop: "flow",
            event: "change"
        },
        components: {
            deptPicker
        },
        props: {
            flow: Array,
            extraApply: Array,
            approveUnchangeable: Boolean,
            ccUnDelete: Boolean,
            // 审批流程展示方式 apply 申请时的流程 detail 审批详情流程
            type: {
                type: String,
                value: "detail"
            },
        },
        data() {
            return {
                roleIcon: nodeRoleIcon,
                children: [],
                visible: false,
                index: null,
                selectItems: []
            }
        },
        computed: {
            list: {
                get() {
                    return this.flow || [];
                },
                set(val) {
                    this.$emit("change", val);
                }
            }
        },
        created() {
            this.setChildren();
        },
        methods: {
            showUserApply(apply) {
                this.$emit("show", apply);
            },
            addBook(index) {
                this.index = index;
                this.visible = true;
            },
            handleDeptSelect(list) {
                this.selectItems = list || [];
            },
            confirmSelect() {
                const select = this.selectItems.map(item => item.userid);
                if(select.length > 0) {
                    const index = this.index;
                    const list = [...this.list];
                    const item = list[index];
                    const ids = item.ids || [];
                    select.forEach(id => {
                        if(ids.indexOf(id) === -1) {
                            ids.push(id);
                        }
                    })
                    item.ids = ids;
                    this.list = list;
                }
                this.visible = false;
            },
            removeEmpty(index) {
                const list = [...this.list];
                list[index].userEmpty = false;
                this.list = list;
            },
            removeBook(index, i) {
                const list = [...this.list]
                let item = list[index];
                item.ids.splice(i, 1);
                this.list = list;
            },
            setChildren() {
                this.children = this.extraApply ? [...this.extraApply] : [];
            },
            openChild(child) {
                this.$set(child, "open", !child.open);
            }
        }
    }
</script>

<style lang="less">
  .apply-process-list {
    margin-top: 10px;
    padding-left: 24px;
  }

  .apply-process-line {
    position: relative;
  }

  .apply-process-line::before {
    content: "";
    display: block;
    position: absolute;
    top: 21px;
    left: 8px;
    bottom: 3px;
    width: 2px;
    background-color: @primary-color;
    border-radius: 2px;
  }

  .apply-process-title {
    display: flex;
    align-items: center;
    font-size: 12px;
  }

  .apply-process-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 18px;
    height: 18px;
    border-radius: 100%;
    background-color: @primary-color;
    font-size: 12px;
    color: @component-background;
  }

  .apply-process-text {
    margin-left: 6px;
  }

  .add-node:active {
    opacity: 0.4;
  }

  .add-node .apply-process-text {
    color: @primary-color;
    font-weight: 600;
  }

  .apply-dot {
    margin: 0 3px;
    width: 3px;
    height: 3px;
    border-radius: 100%;
    background-color: currentColor;
  }

  .apply-approve-type {
    margin-left: 3px;
    padding: 2px 3px;
    border: 1px solid @primary-color;
    border-radius: 2px;
    color: @primary-color;
    font-size: 12px;
    line-height: 1;
  }

  .apply-process-user {
    margin-left: 23px;
    padding: 2px 0 20px;
  }

  .process-user-item {
    margin-top: 12px;
    &.process-user-empty {
      .process-user-name {
        color: @error-color;
      }
    }
  }

  .process-user-info {
    display: flex;
    align-items: center;
  }

  .process-user-avatar {
    position: relative;
    width: 24px;
    height: 24px;
    border-radius: 2px;
    background-color: rgba(10, 17, 26, 0.4);
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    color: @component-background;
  }

  .process-user-status-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: -6px;
    right: -6px;
    width: 12px;
    height: 12px;
    border-radius: 100%;
    background-color: @component-background;
    color: @text-color-secondary;
    font-size: 12px;
    line-height: 1;
    &.icon-success {
      color: @primary-color;
    }
    &.icon-warn {
      color: @error-color;
    }
  }

  .process-user-name {
    flex: 1;
    margin-left: 6px;
    font-size: 12px;
    color: #666;
  }

  .process-user-remove {
    color: #999;
  }

  .user-name-other {
    margin-left: 0.5em;
    color: #999;
  }

  .process-user-state {
    display: flex;
    align-items: center;
    font-size: 12px;
    color:  #999;
  }
  .process-user-apply {
    margin-top: 8px;
    padding-left: 24px;
    font-size: 12px;
    color: #666;
  }
  .node-empty-tip {
    display: flex;
    align-items: center;
    margin-top: 6px;
    padding: 4px;
    background-color: @background-color-light;
    border-radius: 2px;
    font-size: 12px;
    line-height: 1;
  }
  .node-empty-tip .icon-warn {
    color: @error-color
  }
  .empty-tip-text {
    margin-left: 6px;
    color: @text-color-secondary;
  }
  .process-user-apply .user-state-text {
    flex: 1;
  }
  .process-level {
    padding: 0 0 6px 22px;
  }
  .process-level-temp {
    margin-top: 6px;
    &.process-level-show {
      .level-temp-title {
        .icon-down {
          transform: rotate(180deg);
        }
      }
    }
  }
  .level-temp-title {
    display: flex;
    align-items: center;
    cursor: pointer;
    .icon-down {
      font-size: 8px;
    }
  }
  .level-temp-icon {
    width: 24px;
    height: 24px;
  }
  .level-temp-name {
    flex: 1;
    margin: 0 6px;
  }
  .level-temp-process {
    margin-top: 8px;
  }

  .process-node-book {
    margin: 6px 0 0 30px;
    padding-bottom: 12px;
  }
  .process-node-list {
    display: flex;
    flex-wrap: wrap;
  }
  .node-book-item {
    display: flex;
    align-items: center;
    margin: 0 6px 6px 0;
    padding: 0 12px;
    height: 40px;
    background-color: #F5F6F7;
    border-radius: 3px;
    font-size: 12px;
  }
  .node-book-icon {
    width: 28px;
    height: 28px;
    border-radius: 4px;
    font-size: 16px;
    text-align: center;
    line-height: 28px;
  }
  .node-book-icon {
    position: relative;
    background-color: #FFCE7E;
    color: #FFFFFF;
  }
  .add-node-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    background-color: #EBEDF0;
    border-radius: 3px;
    font-size: 16px;
    color: #FF8C00;
    cursor: pointer;
  }
  .node-book-name {
    margin-left: 6px;
    text-align: center;
  }
  .node-book-empty .node-book-name {
    color: #F04A3E;
  }
  .node-remove {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 8px;
    width: 16px;
    height: 16px;
    color: #999999;
    font-size: 8px;
    cursor: pointer;
  }
  .node-empty-tip {
    display: flex;
    align-items: center;
    margin-top: 5px;
    padding: 5px;
    background-color: #F5F6F7;
    border-radius: 2px;
    font-size: 12px;
    line-height: 1;
  }
  .node-empty-tip .icon-warn {
    color: #FF6963;
  }
  .empty-tip-text {
    margin-left: 12px;
    color: #999999;
  }
</style>

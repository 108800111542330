import {
    isArray,
    getFileSizeText,
    getUrlFileName,
    getSuffix, getKeyTitle, isEmpty
} from "../js/tool";
import {
    getUserWxId
} from "./storage";
import {
    base
} from "../../components/icon-select";
import attendanceItems from "./expand/attendance";
import mealItems from "./expand/meal";
import volunteerItems from "./expand/volunteer";

export const nodeRoleIcon = ['', 'icon-approve', 'icon-cc', 'icon-dispatch', 'icon-order'];

export const plateForm = [
    {
        component: 'car',
        key: 'car_list',
        label: '车辆',
        limit: 10,
        show_in_list: true,
        rules: [
            {required: true, message: "请添加车辆"}
        ]
    },
    {
        component: "half-date",
        key:"half-date_3KWWrDf5",
        label:"进入时间",
        placeholder:"请选择时间",
        rules: [
            {required: true, message: "请选择进入时间"}
        ]
    },
    {
        component: "half-date",
        key:"half-date_sWCiTn2k",
        label:"离开时间",
        placeholder:"请选择时间",
        rules: [
            {required: true, message: "请选择离开时间"}
        ]
    }
];

export const mealList = ['早', '中', '晚']

export const applyStatus = [
    {
        value: 1,
        title: '审批中'
    },
    {
        value: 2,
        title: '已驳回'
    },
    {
        value: 3,
        title: '已撤销'
    },
    {
        value: 4,
        title: '已通过'
    },
    {
        value: 5,
        title: '已完成'
    },
    {
        value: 6,
        title: '已取消'
    },
    {
        value: 7,
        title: '已拒绝'
    },
]


export const plateIssueStatusList = [
    {
        value: 0,
        title: '未下发'
    },
    {
        value: 1,
        title: '下发成功'
    },
    {
        value: 2,
        title: '下发失败'
    },
]

export const applyNodeStatus = [
    {
        value: 0,
        title: ["审批中", "抄送中", "派单中", "接单中"],
        icon: 'none'
    },
    {
        value: 1,
        title: ["已同意", "已抄送", "已派单", "已接单"]
    },
    {
        value: 2,
        title: ["已驳回", "已驳回", "已驳回", "已驳回"],
        icon: 'icon-warn'
    },
    {
        value: 3,
        title: ["已转交", "已转交", "已转交", "已转交"],
        icon: 'icon-refer'
    },
    {
        value: 4,
        title: ["已完成", "已完成", "已完成", "已完成"]
    }
]

export const assignTypeList = [
    {type: 'car', name: '车辆'},
    {type: 'box', name: '包厢'},
]
export const fileExtensionIconType = {
    image: ['.pjp', '.jfif', 'pjpeg', '.avif', '.ico', '.tiff', '.gif', '.svg', '.bmp', '.png', '.xbm', '.jxl', '.jpeg', '.svgz', '.jpg', '.webp'],
    video: ['.mp4', '.avi', '.wmv', '.mpg', '.mpeg', '.mov', '.flv'],
    audio: ['.mp3', '.wav'],
    word: ['.doc', '.docx'],
    excel: ['.xls', '.xlsx'],
    ppt: ['.ppt', '.pptx'],
    pdf: ['.pdf'],
    text: ['.txt']
}

export const getFileTypeIcon = function (path) {
    let extension = getSuffix(path);
    let type = 'unknown';
    if(extension) {
        let keys = Object.keys(fileExtensionIconType);
        for(let i = 0, l = keys.length; i < l; i ++) {
            let key = keys[i];
            let index = fileExtensionIconType[key].indexOf(extension);
            if(index >= 0) {
                type = key;
                break;
            }
        }
    }
    return type;
}

export const getApplyStatusText = function (status) {
    let res = applyStatus.find(s => s.value == status)
    return res ? res.title : null;
}

export const getPlateIssueStatusText = function (status) {
    let res = plateIssueStatusList.find(s => s.value == status);
    return res ? res.title : null;
}

export const getApplyState = function (apply) {
    return apply.car_num_type == 2 ? getPlateIssueStatusText(apply.car_num_status) : getApplyStatusText(apply.status);
}

export const getApplyTempName = function (apply) {
    return apply.car_num_type == 2 ? '车牌下发' : apply.applyTemp?.name;
}

export const getApplyNodeStatusText = function (status, type) {
    let res = applyNodeStatus.find(s => s.value == status)
    return res ? res.title[type - 1] : null;
}

export const getApplyNodeStatusIcon = function (status) {
    let res = applyNodeStatus.find(s => s.value == status);
    if (!res || res.icon === 'none') {
        return '';
    } else {
        return res.icon || 'icon-success';
    }
}

export const getAssignTypeName = function (type) {
    let res = assignTypeList.find(s => s.type == type)
    return res ? res.name : null;
}


export const getFormItemList = function (config) {
    let list = [];
    if (config) {
        config.forEach(item => {
            switch (item.component) {
                case "attendance":
                    list.push(...(attendanceItems[item.type] || []));
                    break;
                case "meal":
                    list.push(...mealItems);
                    break;
                case "volunteer_modify":
                    list.push(...(volunteerItems[item.type || "status"] || []));
                    break;
                case "reception":
                    break;
                default:
                    list.push(item);
            }
        });
    }
    return list;
}

export const constructForm = function (form, detail, config) {
    let carList = [], imgList = [];
    const list = getFormItemList(config);
    list.forEach(item => {
        let value = detail[item.key];
        // 请假类型值是一个对象
        if (item.key === 'leave_type') {
            value = value.name;
        } else if (item.key === 'meal_time') {
            // 请假时间展示值特殊处理
            let sd = new Date(value.start.day).pattern( "M月d日"), sm = mealList[value.start.meal];
            let ed = new Date(value.end.day).pattern( "M月d日"), em = mealList[value.end.meal];
            value = `${sd} ${sm} - ${ed} ${em}`;
        }
        if(!isEmpty(value)) {
            switch(item.component) {
                // 管理后台选择框表单
                case "a-select":
                    if(item.props.options && item.props.options.length > 0) {
                        let opt = item.props.options[0];
                        if(typeof opt === 'object') {
                            value = getKeyTitle(item.props.options, value);
                        }
                    }
                    break;
                // 管理后台上传表单
                case "form-upload":
                    // 上传值兼容处理
                    if (!isArray(value)) {
                        if(item.mediaType == 'mix') {
                            value = JSON.parse(value);
                        } else {
                            value = value.split(",").map(url => {
                                return {url};
                            });
                        }
                    }
                    value.forEach(f => {
                        f.fileName = getUrlFileName(f.url);
                        f.type = "image";
                        // f.sizeText = getFileSizeText(f.size);
                        imgList.push({
                            url: f.url,
                            title: `${item.label} ${f.fileName}`
                        })
                    });
                    item.component = 'upload';
                    break;
                // 管理后台通讯录选择表单
                case "select-book":
                    if(item.props.type == 'user' || item.props.type == 'dept') {
                        value = value.map(b => {return {id: b, type: item.props.type == 'dept' ? 'department' : 'user'}});
                    }
                    item.component = 'book';
                    break;
                // 上传值兼容处理
                case "upload":
                    if (!isArray(value)) {
                        value = [{
                            url: value
                        }];
                    }
                    value.forEach(f => {
                        f.fileName = f.fileName || getUrlFileName(f.url);
                        f.sizeText = getFileSizeText(f.size);
                        f.type = getFileTypeIcon(f.url);
                        if(f.type == 'image') {
                            imgList.push({
                                url: f.url,
                                title: `${item.label} ${f.fileName}`
                            })
                        }

                    });
                    break;
                case "asset":
                    // 兼容单个情况
                    if (!isArray(value)) {
                        value = [value];
                    }
                    break;
                case "half-date":
                    if (isArray(value)) {
                        value = `${value[0]} ${['上午', '下午'][value[1]]}`
                    }
                    break;
                case "car":
                    if (typeof value === 'string') {
                        carList.push(...value.split(","));
                    } else {
                        carList.push(...value.map(item => item.num));
                    }
                    break;
                case "subscribe":
                    if (Array.isArray(value)) {
                        if(value[0].purchaseOrderNumber) {
                            value = value[0].purchaseOrderInfo.children.map(e => ({
                                amount: e.num,
                                goodsName: e.subscribe_goods
                            }))
                        } else if(value[0].subscribe_num) {
                            value = value.map(g => new Object({	amount: g.num, goodsName: g.subscribe_goods }));
                        }
                    }
                    break;
                case "book":
                case "address_book":
                    if (item.type == 'mix') {
                        let user = value.user.map(u => {return {id: u, type: 'user'}});
                        let department = value.department.map(d => {return {id: d, type: 'department'}});
                        value = user.concat(department);
                    } else {
                        value = Array.isArray(value) ? value : [value];
                        value = value.map(b => {return {id: b, type: item.type}});
                    }
                    break;
                case "tk":
                    if(typeof value === "object") {
                        value = value.name
                    }
                    break;
                case "input":
                    if (item.suffix) {
                        value = value + item.suffix;
                    }
                    break;
            }
        }
        form.push({
            key: item.key,
            label: item.label,
            value,
            component: item.component
        });
    });
    return {carList, imgList};
}

export const dealFlow = function (apply) {
    let btns = []; // 操作按钮
    if (apply.real_flow) {
        try {
            let flow = JSON.parse(apply.real_flow);
            flow = flow.filter(f => f.type !== 0);
            const extendApply = apply.extendApply || [];
            let flowStatus = apply.flow_status ? JSON.parse(apply.flow_status) : [];
            flow.forEach((item, index) => {
                const nodeApplyList = extendApply.filter(a => a.extend_node_id == item.node_id);
                let temp = flowStatus.find(f => f.node_id == item.node_id);
                if (temp) {
                    item.status = temp.node_status;
                    item.statusText = getApplyNodeStatusText(item.status, item.type);
                } else if (item.node_id == apply.node) {
                    // 流程走到当前节点
                    item.status = 0;
                    item.statusText = getApplyNodeStatusText(item.status, item.type);
                    apply.statusText = item.statusText;
                }
                if (item.ids) {
                    item.userList = item.ids.map(id => {
                        let res = {
                            id,
                            status: 0
                        };
                        if (temp && temp.detail && temp.detail[id]) {
                            let nodeUserDetail = temp.detail[id];
                            res.status = nodeUserDetail.apply_status;
                            if (res.status > 0) {
                                res.statusText = getApplyNodeStatusText(res.status, item.type);
                                res.icon = getApplyNodeStatusIcon(res.status);
                                res.time = nodeUserDetail.update_time;
                                if (nodeUserDetail.note) {
                                    res.note = nodeUserDetail.note;
                                }
                                res.is_complete = nodeUserDetail.is_complete || 0;
                            }
                        }
                        const userApplyList = nodeApplyList.filter(a => a.user_id == res.id);
                        if (userApplyList.length > 0) {
                            userApplyList.forEach(ua => {
                                dealApply(ua);
                                const applyTemp = ua.applyTemp;
                                if (applyTemp) {
                                    ua.iconPath = base + applyTemp.icon;
                                    ua.name = ua.tempName;
                                }
                            });
                            res.extendApplyList = userApplyList;
                        }
                        return res;
                    });
                }
                // 有子需求单
                if (apply.extraApply && apply.extraApply.length > 0) {
                    apply.extraApply.forEach(a => {
                        dealApply(a);
                        const applyTemp = a.applyTemp;
                        if(applyTemp) {
                            a.iconPath = base + applyTemp.icon;
                            a.name = a.tempName;
                        }
                    });
                } else if (apply.extra_apply) {
                    try {
                        let extraApply = JSON.parse(apply.extra_apply);
                        extraApply.forEach(ea => {
                            let detailForm = [];
                            let ed = JSON.parse(ea.detail);
                            let ec = JSON.parse(ea.temp_snap);
                            constructForm(detailForm, ed, ec);
                            ea.iconPath = base + ea.temp_icon,
                                ea.name = ea.temp_name,
                                ea.form = detailForm;
                            const flow = ea.real_flow ? JSON.parse(ea.real_flow) : [];
                            flow.forEach(node => node.userList = node.ids ? node.ids.map(id => Object({ id })) : []);
                            ea.flow = flow;
                        });
                        apply.extraApply = extraApply;
                    } catch (e) {
                        console.error(e);
                    }
                }
                // 计算审批message的节点状态
                // 需当前apply的审批节点为此节点
                if (apply.message && apply.status == 1 && apply.message.node_id == apply.node && apply.node == item.node_id) {
                    if(item.userList) {
                        let wxId = getUserWxId();
                        let nodeUser = item.userList.find(u => u.id == wxId);
                        if(nodeUser) {
                            // 此节点包含当前用户根据此节点的审批状态和节点用户的审批状态显示操作按钮
                            btns = getBtns(item, nodeUser);
                            if (nodeUser.status == 0) {
                                // 最后一个节点
                                if (index == flow.length - 1) {
                                    apply.last = true;
                                    // 可指定下一个节点
                                    if (item.status == 0 && item.custom_next == 1) {
                                        //或签显示添加节点 会签判断是否是此节点最后一个审批人显示添加节点
                                        if(item.approval_type == 1) {
                                            let approveUser = item.userList.filter(u => u.status == 0);
                                            if(approveUser.length == 1 && approveUser[0].id == nodeUser.id) {
                                                apply.custom = true;
                                                item.showLine = true;
                                            }
                                        } else if(item.approval_type == 2){
                                            apply.custom = true;
                                            item.showLine = true;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            });
            apply.flow = flow;
            apply.btns = btns;
        } catch (e) {
            console.error(e);
        }
    }
}

export const dealApply = function (apply) {
    apply.statusText = getApplyState(apply);
    apply.tempName = getApplyTempName(apply);
    let form = [{
        label: "提交时间",
        value: apply.create_time
    }];
    if (apply.detail) {
        try {
            let detail = JSON.parse(apply.detail);
            let config = JSON.parse(apply.temp_snap);
            let {carList, imgList} = constructForm(form, detail, config);
            apply.carList = carList;
            apply.imgList = imgList;
        } catch (e) {
            console.error(e);
        }
    }

    if(apply.select) {
        try {
            let select = JSON.parse(apply.select);
            apply.assignItem = {...select.item, typeName: getAssignTypeName(select.type), itemValue: select.type == 'car' ? select.item.num : select.item.name};
        } catch (e) {
            console.error(e);
        }
    }
    apply.form = form;
    dealFlow(apply);
}

export const dealReport = function (report) {
    let form = [{
        label: "汇报时间",
        value: report.create_time
    }];
    if (report.detail) {
        try {
            let detail = JSON.parse(report.detail);
            let config = JSON.parse(report.detail_snap);
            let {imgList} = constructForm(form, detail, config);
            report.imgList = imgList;
        } catch (e) {
            console.error(e);
        }
    }
    report.form = form;
}

const getBtns = function (node, user) {
    let btns = [];
    if (node.status == 0 && user.status == 0) {
        // 接单节点
        if (node.type == 1) {
            btns = [{
                title: '驳回',
                type: 'reject'
            },
                {
                    title: '同意',
                    type: 'agree'
                },
            ]
        } else if (node.type == 3) {
            btns = [{
                title: '驳回',
                type: 'reject'
            },
                {
                    title: '派单',
                    type: 'dispatch'
                },
            ]
        } else if (node.type == 4) {
            btns = [{
                title: '转交',
                type: 'refer'
            },
                {
                    title: '接单',
                    type: 'order'
                },
            ]
        }
    } else if (node.status == 1 && user.status == 1 && user.is_complete == 0) {
        if (node.type == 4) {
            btns = [{
                title: '完成',
                type: 'complete'
            }]
        }
    }
    return btns;
}

<template>
  <form-area
    ref="adminForm"
    class="flex-box vertical content md"
    layout="horizontal"
    :label-col="{ span: 4 }"
    :wrapper-col="{ span: 20 }"
    :items="items"
    :entity="form"
    confirm-text="确定"
    @cancel="cancel"
    @confirm="confirm"
    v-if="show"
  ></form-area>
</template>

<script>
import { remindOptions, levelOptions } from "../../common/constant/safe";

export default {
  name: "SafeLevel",
  data() {
    return {
      show: false,
      form: {},
      items: [
        {
          key: "define",
          label: "预警定义",
          component: "define",
        },
        {
          key: "head_wx_user_id",
          label: "设置负责人",
          component: "select-book",
          props: {
            type: "user",
            multiple: true,
          },
          rules: [
            { required: true, message: "请选择负责人", trigger: "change" },
          ],
        },
        {
          key: "copy_wx_user_id",
          label: "设置抄送人",
          component: "select-book",
          props: {
            type: "book",
            multiple: true,
          },
          rules: [
            { required: false, message: "请选择抄送人", trigger: "change" },
          ],
        },
        {
          key: "remind_method",
          label: "提醒方式",
          component: "a-checkbox-group",
          props: {
            options: remindOptions,
          },
          rules: [
            { required: true, message: "请选择提醒方式", trigger: "change" },
          ],
        },
        {
          key: "remind",
          label: "未处理提醒",
          component: "remind",
          rules: [
            {
              required: false,
              message: "请填写提醒间隔及次数",
              trigger: "change",
            },
          ],
          listeners: {
            change(val) {
              if (val) {
                this.$set(this.form, "minute", val.minute);
                this.$set(this.form, "number", val.number);
              } else {
                this.$set(this.form, "minute", null);
                this.$set(this.form, "number", null);
              }
            },
          },
        },
        {
          key: "annex",
          label: "应急预案文件",
          component: "form-upload",
          props: {
            limit: -1,
            listType: "text",
            customAccept:
              ".excel,.pdf,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document",
            size: 100 * 1024 * 1024,
          },
          rules: [
            {
              required: false,
              message: "请选择应急预案文件",
              trigger: "change",
            },
          ],
        },
        {
          key: "programme",
          label: "应急预案内容",
          component: "rich-editor",
          props: {
            placeholder: "请填写应急预案内容",
          },
          rules: [
            { required: false, message: "请填写应急预案内容", trigger: "blur" },
          ],
        },
        {
          key: "step",
          label: "应急处置步骤",
          component: "rich-editor",
          props: {
            placeholder: "请填写应急处置步骤",
          },
          rules: [
            { required: false, message: "请填写应急处置步骤", trigger: "blur" },
          ],
        },
      ],
    };
  },
  computed: {
    id() {
      return this.$route.params.id;
    },
  },
  created() {
    this.getDetail();
  },
  methods: {
    getDetail() {
      this.$axios({
        url: `/admin/security-set/${this.id}`,
        method: "GET",
      }).then((res) => {
        this.dealInfo(res);
        this.form = res;
        if (this.id) {
          let target = levelOptions.find((item) => item.value == res.level);
          if (target) {
            this.form.define = target.define;
          }
        }
        this.show = true;
      });
    },
    dealInfo(info) {
      info.remind = {
        minute: info.minute,
        number: info.number,
      };
      info.remind_method = info.remind_method.split(",");
      if (info.head_wx_user_id) {
        info.head_wx_user_id = info.head_wx_user_id.split(",");
      }
      if (info.copy_wx_user_id) {
        info.copy_wx_user_id = JSON.parse(info.copy_wx_user_id);
      }
    },
    cancel() {
      this.$router.back();
    },
    confirm(form) {
      let data = {
        remind_method: form.remind_method,
        annex: form.annex,
        programme: form.programme,
        step: form.step,
      };
      if (form.head_wx_user_id) {
        data.head_wx_user_id = form.head_wx_user_id.join(",");
      }
      if (form.copy_wx_user_id) {
        data.copy_wx_user_id = JSON.stringify(form.copy_wx_user_id);
      }
      if (form.remind_method) {
        data.remind_method = form.remind_method.join(",");
      }
      if (form.remind) {
        data.minute = form.minute;
        data.number = form.number;
      }
      this.$axios({
        url: `/admin/security-set/${this.id}`,
        method: "PATCH",
        data,
      }).then(() => {
        this.$message.success("设置成功");
        this.$router.back();
      });
    },
  },
};
</script>

<style lang="less" scoped></style>

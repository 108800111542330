<template>
  <div class="buddha-tablet-info">
    <div class="buddha-tablet-list" v-if="list && list.length > 0">
      <div class="buddha-tablet-item" v-for="(t, i) in list" :key="i">
        <a-popconfirm
          title="确定删除此牌位吗?"
          ok-text="确定"
          cancel-text="取消"
          @confirm="delTablet(i)"
          v-if="edit"
        >
          <div class="txt-btn danger delete-tablet">删除</div>
        </a-popconfirm>
        <a-form-model
          class="tablet-form"
          ref="tabletForm"
          :model="t"
          :rules="rules"
          v-if="edit">
          <a-form-model-item label="选择类型" prop="type" v-if="temp.cat == 3">
            <a-radio-group :options="typeList" v-model="t.type" @change="setRelation(t)"></a-radio-group>
          </a-form-model-item>
          <a-form-model-item label="关系" prop="relation" v-if="t.hasRelation">
            <a-select v-model="t.relation" :options="relationList" placeholder="请选择关系" @change="setTabletConfig(t)" style="width: 100%"></a-select>
          </a-form-model-item>
          <a-form-model-item label="对象" prop="user_type" v-else-if="temp.cat == 3">
            <a-radio-group v-model="t.user_type" :options="objectTypeList"></a-radio-group>
          </a-form-model-item>
          <a-form-model-item label="公司/团体名称" prop="name" v-if="userType == 2 || t.user_type == 2">
            <a-input v-model="t.name" placeholder="公司/团体名称"></a-input>
          </a-form-model-item>
          <template v-else>
            <a-form-model-item label="牌位" prop="personList" v-if="t.personNumber > 0">
              <div class="tablet-person-item" v-for="(p, j) in t.personList || []" :key="j">
                <form-area
                  :ref="`person_form_${i}`"
                  class="tablet-person-form"
                  layout="inline"
                  hide-btn
                  direct
                  :items="t.tabletConfig.personItems"
                  :entity="p">
                  <template v-slot:date="{form: config, control}">
                    <a-input-group compact>
                      <a-select v-model="config[`${control.key}DateType`]" :options="dateTypeOptions" style="width: 72px;"></a-select>
                      <a-date-picker
                        placeholder="请选择日期"
                        format="YYYY-MM-DD"
                        value-format="YYYY-MM-DD"
                        :disabled-date="disabledDate"
                        v-model="config[control.key]"
                        @change="handleDateChange(i, j, control.key)"
                        style="width: 180px;"
                        v-if="config[`${control.key}DateType`] == 1"/>
                      <lunar-picker
                        placeholder="请选择日期"
                        :disabled-date="disabledDate"
                        v-model="config[control.key]"
                        @change="handleDateChange(i, j, control.key)"
                        style="width: 180px;" v-else>
                      </lunar-picker>
                    </a-input-group>
                  </template>
                </form-area>
                <a-icon class="tablet-person-remove" type="close-circle" theme="filled" @click="removeItem(t, j, 'person')" v-if="!part"/>
              </div>
              <a-button size="small" type="link" @click="addItem(t, 'person')" v-show="(t.personList?.length || 0) < t.personNumber" v-if="!part">
                <a-icon type="plus" />
                <span>添加</span>
              </a-button>
            </a-form-model-item>
            <a-form-model-item label="阳上" prop="posterityList" v-if="t.posterityNumber > 0">
              <a-row>
                <a-col :span="12" v-for="(p, j) in t.posterityList || []" :key="j">
                  <div class="tablet-person-item">
                    <form-area
                      :ref="`posterity_form${i}`"
                      class="tablet-person-form"
                      layout="inline"
                      hide-btn
                      direct
                      :items="t.tabletConfig.posterityItems"
                      :entity="p">
                    </form-area>
                    <a-icon class="tablet-person-remove" type="close-circle" theme="filled" @click="removeItem(t, j, 'posterity')" v-if="!part"/>
                  </div>
                </a-col>
              </a-row>
              <a-button size="small" type="link" @click="addItem(t,'posterity')" v-show="(t.posterityList?.length || 0) < t.posterityNumber" v-if="!part">
                <a-icon type="plus" />
                添加
              </a-button>
            </a-form-model-item>
          </template>
          <template v-if="temp.cat != 3">
            <a-form-model-item label="所在地区" prop="area">
              <a-cascader :options="city" :show-search="{ filter }" placeholder="请选择所在地区" v-model="t.area"/>
            </a-form-model-item>
            <a-form-model-item label="详细地址" prop="address">
              <a-input v-model="t.address" placeholder="请输入详细地址"></a-input>
            </a-form-model-item>
          </template>
        </a-form-model>
        <div class="tablet-item-info" v-else>
          <div class="buddha-tablet-title" v-if="edit">牌位{{i + 1}}<span class="txt-btn" @click="showEditTablet(i)">修改</span><span class="txt-btn danger" @click="delTablet(i)" v-if="!part">删除</span></div>
          <div class="buddha-tablet-title" v-else-if="list.length > 1">牌位{{i + 1}}</div>
          <div class="tablet-info-item" v-if="temp.cat == 3">
            <div class="buddha-tablet-label">类型</div>
            <div class="buddha-tablet-text">{{getBuddhaSubTypeName(temp.fs_type, t.type)}}</div>
          </div>
          <div class="tablet-info-item" v-if="t.cat == 2">
            <div class="buddha-tablet-label">关系</div>
            <div class="buddha-tablet-text">{{buddhism.getBuddhismRelationText(t.relation)}}</div>
          </div>
          <div class="tablet-info-item" v-else>
            <div class="buddha-tablet-label">对象</div>
            <div class="buddha-tablet-text">{{buddhism.getBuddhismObjectTypeText(t.user_type)}}</div>
          </div>
          <div class="tablet-info-item" v-if="t.user_type == 2">
            <div class="buddha-tablet-label">公司/团体名称</div>
            <div class="buddha-tablet-text">{{t.name || (t.personList && t.personList.length > 0  ? t.personList[0].name : '')}}</div>
          </div>
          <template v-else>
            <div class="tablet-info-item" v-if="t.personList && t.personList.length > 0">
              <div class="buddha-tablet-label">牌位</div>
              <div class="buddha-tablet-text">
                <div class="buddha-text-item" v-for="(person, i) in t.personList" :key="i">
                  <span class="text-item-span" v-if="person.relation">{{person.relation}}</span>
                  <span class="text-item-span">{{person.name}}</span>
                  <div class="flex-grow">
                    <div class="buddha-text-item">
                      <span class="text-item-span" v-if="person.birth">{{person.die ? '生于' : '生日 '}}{{person.birthDateType == 2 ? `农历${getLunarDay(new Date(person.birth), true, true)}` : `公历 ${person.birth}`}}</span>
                      <span class="text-item-span" v-if="person.birthTime || person.time">{{buddhism.getTimeText(person.birthTime || person.time)}}</span>
                    </div>
                    <div class="buddha-text-item" v-if="person.die">
                      <span class="text-item-span">卒于{{person.dieDateType == 2 ? `农历${getLunarDay(new Date(person.die), true, true)}` : `公历${person.die}`}}</span>
                      <span class="text-item-span" v-if="person.dieTime">{{buddhism.getTimeText(person.dieTime)}}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="tablet-info-item" v-if="t.posterityList && t.posterityList.length > 0">
              <div class="buddha-tablet-label">阳上</div>
              <div class="buddha-tablet-text">
                <div class="buddha-text-item" v-for="(posterity, i) in t.posterityList" :key="i">
                  <span class="text-item-span" v-if="posterity.relation">{{posterity.relation}}</span>
                  <span class="text-item-span">{{posterity.name}}</span>
                  <span class="text-item-span" v-if="posterity.birth">生日 {{posterity.birth}} {{getLunarDay(new Date(posterity.birth), true)}}</span>
                </div>
              </div>
            </div>
          </template>
          <div class="tablet-info-item">
            <div class="buddha-tablet-label">地址</div>
            <div class="buddha-tablet-text">{{(t.area || []).join("") + (t.address || '无')}}</div>
          </div>
        </div>
      </div>
    </div>
    <a-button icon="plus-circle" @click="showAddTablet" v-if="edit && !part">添加牌位</a-button>
    <a-modal
      :title="index === null ? '添加牌位' : '编辑牌位'"
      :mask-closable="false"
      :visible="dv"
      :width="800"
      :body-style="{paddingBottom: '69px'}"
      @cancel="onClose"
      @ok="confirmTablet"
      v-if="edit">
    </a-modal>
  </div>
</template>

<script>
    import moment from 'moment';
    import {clone, getRadioOptionsFromSelect, omit} from "../../common/js/tool";
    import * as buddhism from "../../common/buddha/buddhism";
    import {getLunarDay} from "../calendar/lunar";
    import {getBuddhaSubTypeName} from "../../common/buddha/type";
    import city from "../../common/constant/city";

    export default {
        name: "buddha-tablet",
        model: {
            event: "change"
        },
        props: {
            value: Array,
            temp: Object, // 佛事/法会对象
            typeList: Array,
            type: String, //已选择的佛事/法会种类
            userType: Number, // 已选择对象类型
            edit: {
                type: Boolean,
                default: true
            },
            // 部分修改
            part: Boolean
        },
        data() {
            return {
                buddhism,
                city,
                list: [],
                objectTypeList: getRadioOptionsFromSelect(buddhism.buddhismObjectTypeList),
                relationList: buddhism.getBuddhismTypeOptions(buddhism.buddhismRelationList),
                form: {
                    personList: [],
                    posterityList: []
                },
                person: {},
                dateTypeOptions: [
                    {value: 1, label: '公历'},
                    {value: 2, label: '农历'},
                ],
                rules: {
                    type: [{required: true, message: '请选择类型', trigger: 'change'}],
                    user_type: [{required: true, message: '请选择对象', trigger: 'change'}],
                    relation: [{required: true, message: '请选择关系', trigger: 'change'}],
                    personList: [
                        {required: true, message: '请添加牌位', trigger: 'change'},
                      //   {
                      //       validator: (rule, value, callback) => {
                      //           if (value && value.length > 0) {
                      //               Promise.all(this.$refs.personForm.map(f => f.handleConfirm())).then(() => {
                      //                   callback();
                      //               }).catch(() => {
                      //                   callback(new Error("请正确填写牌位信息"))
                      //               })
                      //           } else {
                      //               callback(new Error('请至少添加一个牌位'));
                      //           }
                      //       },
                      //       trigger: 'change'
                      // }
                    ],
                    posterityList: [
                        {required: true, message: '请添加阳上', trigger: 'change'},
                        // {
                        //     validator: (rule, value, callback) => {
                        //         if (value && value.length > 0) {
                        //             Promise.all(this.$refs.posterityForm.map(f => f.handleConfirm())).then(() => {
                        //                 callback();
                        //             }).catch(() => {
                        //                 callback(new Error("请正确填写阳上信息"))
                        //             })
                        //         } else {
                        //             callback(new Error('请至少添加一个阳上'));
                        //         }
                        //     },
                        //     trigger: 'change'
                        // }
                    ],
                    area: [{required: true, message: '请选择所在地区', trigger: 'change'}],
                    address: [{required: true, message: '请输入详细地址', trigger: 'blur'}],
                    username: [{required: true, message: '请输入府上名称', trigger: 'blur'}],
                    contact_person: [{required: true, message: '请输入联系人', trigger: 'blur'}],
                    tel: [{required: true, message: '请输入联系电话', trigger: 'blur'}],
                },
                dv: false,
                mv: false,
                mt: 'person',
                mi: null,
                index: null
            }
        },
        watch: {
            personNumber() {
                this.setList("person");
            },
            posterityNumber() {
                this.setList("posterity");
            },
            type() {
                this.list = [];
                // 自动添加一个
                this.showAddTablet();
            },
            list: {
                handler() {
                    this.dispatchChange();
                },
                deep: true
            }
        },
        created() {
            this.init();
        },
        methods: {
            getLunarDay,
            getBuddhaSubTypeName,
            init() {
                let value = this.value;
                if (value) {
                    const list = clone(value);
                    list.forEach(item => {
                        this.setRelation(item, true)
                    });
                    this.list = list;
                } else {
                    this.list = [];
                    // 自动添加一个
                    this.showAddTablet();
                }
            },
            filter(inputValue, path) {
                return path.some(option => option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1);
            },
            validateTabletList() {
                return Promise.all(this.list.map((item, i) => {
                    return new Promise((resolve, reject) => {
                        Promise.all([this.validatePerson(item), this.validateTablet(i)]).then(resolve).catch((err) => {
                            reject(`第${i + 1}个牌位信息填写有误：${err}`);
                        });
                    })
                }))
            },
            validatePerson(tablet) {
                return new Promise((resolve, reject) => {
                    let flag = true;
                    if (tablet.personNumber > 0) {
                        const personItems = tablet.tabletConfig.personItems.filter(c => c.rules && c.rules.findIndex(r => !!r.required) >= 0);
                        tablet.personList.forEach(p => {
                            p.flag = personItems.every(c => !!p[c.key]);
                        })
                        flag = tablet.personList.some(p => p.flag);
                    }
                    if (!flag) {
                        reject("请至少添加一个牌位");
                        return;
                    }
                    if (tablet.posterityNumber > 0) {
                        const posterityItems = tablet.tabletConfig.posterityItems.filter(c => c.rules && c.rules.findIndex(r => !!r.required) >= 0);
                        tablet.posterityList.forEach(p => {
                            p.flag = posterityItems.every(c => !!p[c.key]);
                        });
                        flag = tablet.posterityList.some(p => p.flag);
                    }
                    if (!flag) {
                        reject("请至少添加一个阳上");
                    } else {
                        resolve();
                    }
                })
            },
            validateTablet(i) {
                return new Promise((resolve, reject) => {
                    this.$refs.tabletForm[i].validate((valid, obj) => {
                        if (valid) {
                            resolve();
                        } else {
                            let msg = "form error";
                            // 返回第一个表单的错误信息
                            const keys = Object.keys(obj);
                            if (keys.length > 0) {
                                const k = keys[0];
                                const arr = obj[k];
                                if (arr[0]?.message) {
                                    msg = arr[0].message;
                                }
                            }
                            reject(msg);
                        }
                    });
                })
            },
            setRelation(t, init) {
                this.$set(t, 'hasRelation', buddhism.hasRelation({
                    cat: this.temp.cat,
                    type: this.type || t.type
                }));
                this.setTabletConfig(t, init);
            },
            setTabletConfig(t, init){
                const config = buddhism.getTabletConfig({type: this.temp.fs_type, relation: t.relation}, t.hasRelation);
                const personNumber = config && config.person ? config.person.num : 0;
                const posterityNumber = config && config.posterity ? config.posterity.num : 0;
                this.$set(t, 'tabletConfig', config);
                this.$set(t, 'personNumber', personNumber);
                this.$set(t, 'posterityNumber', posterityNumber);
                if (!init) {
                    this.$set(t, 'personList', undefined);
                    this.$set(t, 'posterityList', undefined);
                    if (t.personNumber > 0) {
                        for(let i = 0; i < t.personNumber; i++) {
                            this.addItem(t, 'person');
                        }
                    }
                    if (t.posterityNumber > 0) {
                        for(let i = 0; i < t.posterityNumber; i++) {
                            this.addItem(t, 'posterity');
                        }
                    }
                }
            },
            disabledDate(current) {
                return current && current >= moment().endOf('day')
            },
            handleDateChange(i, j, key) {
                const personForm = this.$refs[`person_form_${i}`]?.[j];
                if (personForm) {
                    personForm.$refs.ruleForm?.validateField(key);
                }
            },
            delTablet(i) {
                this.list.splice(i, 1);
            },
            showEditTablet(i) {
                const form = clone(this.list[i]);
                this.form = form;
                this.index = i;
                this.dv = true;
            },
            showAddTablet() {
                if(this.temp.maxTablet && this.list.length >= this.temp.maxTablet) {
                    this.$message.warning(`最多只能添加${this.temp.maxTablet}个牌位`)
                } else {
                    const t = {};
                    this.list.push(t);
                    this.setRelation(t);
                    this.dispatchChange();
                }
            },
            onClose() {
                this.dv = false;
            },
            dispatchChange() {
                let list = this.list;
                if (this.temp.cat == 1) {
                    list.forEach(item => {
                        item.type = this.type;
                        if(this.userType) {
                            item.user_type = this.userType;
                        }
                    })
                }
                this.$emit("change", list.length > 0 ? list.map(item => omit(item, ['hasRelation', 'tabletConfig', 'personNumber', 'posterityNumber'])) : undefined);
            },
            confirmTablet() {
                this.$refs.tabletForm.validate(valid => {
                    if (valid) {
                        let form = this.form;
                        form.cat = buddhism.hasRelation({cat: this.temp.cat, type: form.type || this.type}) ? 2 : 1;
                        if(this.index === null) {
                            this.list.push(form);
                        } else {
                            this.$set(this.list, this.index, form);
                        }
                        this.dv = false;
                        this.dispatchChange()
                    } else {
                        this.$message.warning('牌位信息填写有误');
                    }
                });
            },
            modalConfirm() {
                this.$refs.personForm.handleConfirm().then(form => {
                    if(form.birth && form.die && new Date(form.die).getTime() < new Date(form.birth)) {
                        this.$message.warning("卒日期不能小于生日期");
                        return;
                    }
                    let list = this.form[`${this.mt}List`];
                    if(this.mi === null) {
                        list.push(form);
                    } else {
                        this.$set(list, this.mi, form);
                    }
                    // 府上名称自动取第一个阳上姓名的第一个字
                    // const pl = this.form.posterityList && this.form.posterityList.length > 0 ? this.form.posterityList : this.form.personList;
                    // if(pl && pl.length > 0) {
                    //     this.form.username = pl[0].name.substring(0,1);
                    // }
                    this.mv = false;
                });
            },
            setList(type) {
                if(!this.part) {
                    this.$set(this.form, `${type}List`, []);
                }
            },
            addItem(t, type) {
                let num = t.tabletConfig[type].num;
                let list = t[`${type}List`];
                if (!list) {
                    list = [];
                    this.$set(t, `${type}List`, list);
                }
                if (list.length < num) {
                    const item = {};
                    const items = t.tabletConfig[`${type}Items`];
                    const keys = items.filter(c => c.slot == 'date').map(c => c.key);
                    // 所有选择日期的表单项默认为公历
                    keys.forEach(k => item[`${k}DateType`] = 1);
                    // 关系默认没有选中过的
                    const options = items.find(c => c.key === 'relation')?.props?.options;
                    if (options && options.length > 0) {
                        const selectRelations = list.map(p => p.relation);
                        const leftOptions = options.filter(o => !selectRelations.includes(o.key));
                        if (leftOptions.length > 0) {
                            item.relation = leftOptions[0].key;
                        } else {
                            item.relation = options[0].key;
                        }
                    }
                    list.push(item);
                } else {
                    this.$message.warning(`最多只能添加${num}个`)
                }
            },
            editItem(i, type) {
                let item = this.form[`${type}List`][i];
                this.person = clone(item);
                this.mi = i;
                this.mt = type;
                this.mv = true;
            },
            removeItem(t, i, type) {
                t[`${type}List`].splice(i, 1)
            },
        }
    }
</script>

<style lang="less">
  .buddha-tablet-list {
    position: relative;
    margin-bottom: 16px;
  }
  .delete-tablet {
    position: absolute;
    top: 16px;
    right: 16px;
    cursor: pointer;
    line-height: 1;
    z-index: 1;
  }
  .buddha-tablet-item {
    position: relative;
    padding: 8px 16px;
    border: var(--border);
    border-radius: 8px;
    background-color: #ffffff;
    &:not(:last-of-type) {
      margin-bottom: 16px;
    }
  }
  .buddha-tablet-title {
    line-height: 1.5;
    .txt-btn {
      margin-left: 1em;
    }
  }
  .tablet-info-item {
    display: flex;
    padding: 4px 0;
    line-height: 1.5;
  }
  .buddha-tablet-label {
    min-width: 80px;
    color: @text-color-secondary;
  }
  .buddha-tablet-text {
    flex: 1;
    margin-left: 20px;
    color: @text-color;
    text-align: justify;
  }
  .buddha-text-item {
    display: flex;
    align-items: flex-start;
    gap: 1em;
  }
  .tablet-drawer-footer {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: @component-background;
  }

  .add-tablet-item {
    color: @primary-color;
    cursor: pointer;
  }

  .tablet-person-item {
    position: relative;
    &:not(:first-of-type) {
      margin-top: 8px;
      padding-top: 8px;
      border-top: var(--border);
    }
  }

  .tablet-person-text {
    margin-right: 6px;
  }
  .tablet-person-edit,
  .tablet-person-remove {
    position: absolute;
    top: 11px;
    width: 18px;
    height: 18px;
    font-size: 18px;
    color: @text-color-secondary;
    cursor: pointer;
  }
  .tablet-person-remove {
    right: 24px;
  }
  .tablet-person-edit {
    right: 30px;
  }
  .person-date-show {
    display: inline-flex;
    align-items: center;
    position: relative;
    padding: 0 11px;
    width: 100%;
    height: 32px;
    border-radius: 0 4px 4px 0;
    border: 1px solid @normal-color;
    line-height: 1.5;
    cursor: pointer;
    &:hover {
      border-color: @primary-5;
    }
    .person-date-icon {
      position: absolute;
      top: 0;
      right: 10px;
      bottom: 0;
      margin: auto 0;
      width: 14px;
      height: 14px;
      font-size: 14px;
      line-height: 1;
      color: rgba(0, 0, 0, 0.25);
    }
  }
  .person-date-text {
    flex: 1;
    min-width: 0;
    white-space: nowrap;
  }
  .person-date-place {
    color: #bfbfbf;
  }
</style>

  <template>
  <div class="flex-box vertical buddhism-form-box">
    <div class="flex-grow scroll-area buddhism-form-content">
      <a-form-model
        class="buddha-add-form"
        ref="buddhismForm"
        label-align="left"
        layout="horizontal"
        :label-col="{span: 4}"
        :wrapper-col="{span: 20}"
        :model="form"
        :rules="rules"
        v-if="info">
        <template v-if="info.cat == 1">
          <a-form-model-item label="对象" prop="user_type" v-if="!relation">
            <a-radio-group v-model="form.user_type" :disabled="edit" :options="objectTypeList"></a-radio-group>
          </a-form-model-item>
          <a-form-model-item label="预约时间" prop="dateTime">
            <select-date v-model="form.dateTime" :info="info" :type="form.type" :edit="edit" :history="history"></select-date>
          </a-form-model-item>
          <a-form-model-item label="选择类型" prop="type">
            <a-radio-group :options="subTypeList" v-model="form.type" :disabled="edit" @change="handleSubTypeChange"></a-radio-group>
          </a-form-model-item>
          <a-row v-if="info.fs_type == 2">
            <a-col :span="8">
              <a-form-model-item :label-col="{span: 12}" :wrapper-col="{span: 12}" label="经书类型" prop="bookType">
                <a-select v-model="form.bookType" placeholder="请选择经书类型" :disabled="edit" style="width: 100%" @change="handleBookTypeChange">
                  <a-select-option value="scripture">礼忏</a-select-option>
                  <a-select-option value="chanting">诵经</a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col :span="8">
              <a-form-model-item label-align="right" :label-col="{span: 12}" :wrapper-col="{span: 12}" :label="form.bookType == 'scripture' ? '礼忏经书' : '诵经经书'" :prop="form.bookType" v-if="form.bookType">
                <a-select v-model="form[form.bookType]" :placeholder="form.bookType == 'scripture' ? '请选择礼忏经书' : '请选择诵经经书'" style="width: 100%" :disabled="edit" @change="handleChantingChange">
                  <a-select-option :key="s.value" v-for="s in (info.chantingOptions[form.bookType] || [])">{{s.value}}</a-select-option>
                </a-select>
                <a-icon type="plus-circle" class="book-add" @click="addBook" />
              </a-form-model-item>
            </a-col>
            <a-col :span="8">
              <a-form-model-item label-align="right" :label-col="{span: 12}" :wrapper-col="{span: 12}" label="另加诵经人数">
                <a-input-number :min="1" v-model="form.extraNumber" placeholder="另加诵经人数" style="width: 100%"></a-input-number>
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-form-model-item label="诵经人数" prop="number" v-else-if="info.fs_type == 3">
            <a-select v-model="form.number" placeholder="请选择诵经人数" style="width: 180px" v-if="form.type == '07'" :disabled="edit">
              <a-select-option :key="n" v-for="n in info.chantingNumbers.oneNumber || []">{{n}}人</a-select-option>
            </a-select>
            <a-select v-model="form.number" placeholder="请选择诵经人数" style="width: 180px" v-else-if="form.type == '08'" :disabled="edit">
              <a-select-option :key="n" v-for="n in info.chantingNumbers.threeNumber || []">{{n}}人</a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="登记资料" prop="tabletInfo">
            <buddha-tablet ref="buddhaTablet" v-model="form.tabletInfo" :type-list="subTypeList" :temp="info" :type="form.type" :user-type="form.user_type"></buddha-tablet>
          </a-form-model-item>
        </template>
        <a-form-model-item label="登记资料" prop="tabletInfo" v-else-if="info.cat == 3">
          <buddha-tablet ref="buddhaTablet" v-model="form.tabletInfo" :type-list="subTypeList" :temp="info"></buddha-tablet>
        </a-form-model-item>
        <template v-if="buddha.cat == 3 || buddha.cat == 1">
          <a-form-model-item label="府上名称" prop="username" v-if="info.fs_type == 2 || info.fs_type == 3">
            <a-input v-model="form.username" placeholder="请输入府上名称"></a-input>
          </a-form-model-item>
          <a-form-model-item label="联系人" prop="contact_person">
            <a-input v-model="form.contact_person" placeholder="请输入联系人"></a-input>
          </a-form-model-item>
          <a-form-model-item label="联系电话" prop="tel">
            <a-input v-model="form.tel" placeholder="请填写微信绑定手机号"></a-input>
          </a-form-model-item>
          <a-form-model-item label="登记备注">
            <a-textarea v-model="form.commit_note" :rows="4" placeholder="登记备注"></a-textarea>
          </a-form-model-item>
        </template>
        <a-form-model-item label="支付凭证">
          <form-upload :limit="9" v-model="form.bill"></form-upload>
        </a-form-model-item>
        <a-form-model-item label="备注">
          <a-textarea v-model="form.bill_note" :rows="4" placeholder="票据备注"></a-textarea>
        </a-form-model-item>
      </a-form-model>
    </div>
    <a-space class="footer" style="padding-bottom: 0" v-if="info">
      <a-button @click="cancel" v-if="buddhism">取消</a-button>
      <a-button type="primary" :loading="loading" @click="submit">{{buddhism ? '保存' : '提交'}}</a-button>
    </a-space>
    <a-modal
      v-model="visible"
      :width="360"
      title="添加经书"
      :ok-button-props="{ props: { loading: bookLoading, disabled: !book } }"
      @ok="confirmBook">
      <a-input v-model="book" placeholder="请输入经书名称"></a-input>
    </a-modal>
  </div>
</template>

<script>
    import {
        calculateBuddhismPrice,
        dealBuddhaTypeInfo,
        getBuddhaNeedOpen,
        getBuddhaTypeConfig
    } from "../../common/buddha/type";
    import {clone, getRadioOptionsFromSelect, omit} from "../../common/js/tool";
    import buddhaTablet from "./buddha-tablet";
    import {
        buddhismObjectTypeList,
        getBuddhaSubTypeMasterNumberConfig,
        hasRelation,
    } from "../../common/buddha/buddhism";
    import {getUserWxId} from "../../common/js/storage";
    import city from "../../common/constant/city";

    export default {
        name: "buddhism-form",
        components: {
            buddhaTablet
        },
        props: {
            buddha: Object, // fs-temp实体
            buddhism: Object, // fs实体
            history: Boolean, // 是否是历史数据
        },
        data() {
            return {
                city,
                loading: false,
                objectTypeList: getRadioOptionsFromSelect(buddhismObjectTypeList),
                subTypeList: [],
                form: {
                    is_allowance: 1
                },
                rules: {
                    type: [{required: true, message: '请选择类型', trigger: 'change'}],
                    dateTime: [{required: true, message: '请选择预约时间', trigger: 'change'}],
                    user_type: [{required: true, message: '请选择对象', trigger: 'change'}],
                    bookType: [{required: true, message: '请选择经书类型', trigger: 'change'}],
                    scripture: [{required: true, message: '请选择礼忏经书', trigger: 'change'}],
                    chanting: [{required: true, message: '请选择诵经经书', trigger: 'change'}],
                    // tabletInfo: [{required: true, message: '请添加牌位信息', trigger: 'change'}],
                    area: [{required: true, message: '请选择所在地区', trigger: 'change'}],
                    is_allowance: [{required: true, message: '请选择是否参与补贴', trigger: 'change'}],
                    address: [{required: true, message: '请输入详细地址', trigger: 'blur'}],
                    username: [{required: true, message: '请输入府上名称', trigger: 'blur'}],
                    contact_person: [{required: true, message: '请输入联系人', trigger: 'blur'}],
                    tel: [{required: true, message: '请输入联系电话', trigger: 'blur'}],
                },
                extraNumberOptions: null,
                visible: false,
                bookLoading: false,
                book: "",
            }
        },
        computed: {
            info() {
                let res = null;
                if(this.buddha) {
                    res = clone(this.buddha)
                    dealBuddhaTypeInfo(res);
                }
                return res;
            },
            // 是否需要选择关系
            relation() {
                if(this.info) {
                    return hasRelation({
                        cat: this.info.cat,
                        type: this.form.type
                    });
                } else {
                    return true;
                }
            },
            needOpen() {
                let res = false;
                if(this.info) {
                    res = getBuddhaNeedOpen(this.info);
                }
                return res;
            },
            edit() {
                return !!this.buddhism;
            },
            numberConfig() {
                let res = {}
                const subTypeList = this.subTypeList;
                subTypeList.forEach(item => {
                    if(!res[item.value]) {
                        res[item.value] = getBuddhaSubTypeMasterNumberConfig(item.value);
                    }
                });
                return res;
            },
            // 系统配置的全部预约时间段
            timeRange() {
                return this.$store.getters.timeRange || [];
            },
        },
        watch: {
            relation(val) {
                if(!val && !this.form.user_type) {
                    this.$set(this.form, 'user_type', 1);
                } else {
                    this.$set(this.form, 'user_type', null);
                }
            },
            info() {
                this.init();
                this.setForm();
            },
            buddhism() {
                this.setForm();
            },
        },
        created() {
            this.init();
            this.setForm();
        },
        methods: {
            init() {
                let info = this.info;
                let res = [];
                if(info) {
                    let conf = getBuddhaTypeConfig(info.fs_type);
                    if(conf && conf.subTypes) {
                        res = conf.subTypes.map(item => {
                            return {
                                label: item.title,
                                value: item.key
                            }
                        })
                    }
                }
                if(res.length > 0) {
                    const type = res[0].value;
                    this.$set(this.form, 'type', type);
                    this.handleSubTypeChange();
                }
                this.subTypeList = res;
            },
            confirmBook() {
                const info = this.buddha;
                const form = this.form;
                const otherConfig = info.other_config ? JSON.parse(info.other_config) : {};
                const item = { value: this.book, number:11, subType: form.type };
                if (otherConfig[form.bookType]) {
                    otherConfig[form.bookType].push(item)
                } else {
                    otherConfig[form.bookType] = [item];
                }
                this.bookLoading = true;
                this.$axios({
                    url: `/admin/fs-temp/${info.id}`,
                    method: "PATCH",
                    data: {
                        other_config: JSON.stringify(otherConfig)
                    }
                }).then(res => {
                    this.$emit("change", { ...info, ...res });
                    this.$set(form, form.bookType, item.value);
                    this.visible = false;
                }).finally(() => {
                    this.bookLoading = false;
                })
            },
            addBook() {
                this.book = "";
                this.visible = true;
            },
            validateTabletInfo() {
                const buddhaTablet = this.$refs.buddhaTablet;
                return buddhaTablet.validateTabletList();
            },
            handleChantingChange() {
                let res = null;
                const form = this.form;
                const conf = this.numberConfig[form.type || "05"];
                if(conf && conf.max && form.bookType && form[form.bookType]) {
                    const options = this.info.chantingOptions[form.bookType];
                    const number = options.find(o => o.value == form[form.bookType]).number;
                    const offset = conf.max - number;
                    if(offset > 0) {
                        res = new Array(offset).fill(0).map((val, i) => i + 1);
                    }
                    // 之前选择的另加诵经人数超出剩余人数 则置空重新选择
                    if(form.extraNumber > offset) {
                        this.$set(form, 'extraNumber', undefined);
                    } else if(this.edit) {
                        // 编辑状态设置 另加诵经人数值
                        let extraNumber = this.buddhism.extraNumber || 0;
                        if(extraNumber > 0) {
                            this.$set(form, 'extraNumber', extraNumber);
                        } else {
                            // 另加诵经人数小于等于0 不显示
                            res = null;
                        }
                    }
                }
                this.extraNumberOptions = res;
            },
            setForm() {
                if(this.info && this.buddhism) {
                    const buddhism = this.buddhism;
                    const info = this.info;
                    let form = {
                        id: buddhism.id,
                        type: buddhism.type,
                        area: [...(buddhism.area || [])],
                        address: buddhism.address,
                        contact_person: buddhism.contact_person,
                        username: buddhism.username,
                        tel: buddhism.tel,
                        bill: buddhism.bill_img ? JSON.parse(buddhism.bill_img).join(",") : "",
                        bill_note: buddhism.bill_note
                    };
                    if(info.cat == 1) {
                        form.user_type = buddhism.user_type;
                        form.dateTime = {
                            date: buddhism.date,
                            range: buddhism.time_range
                        }
                        if(info.fs_type == 2) {
                            form.bookType = buddhism.scripture ? 'scripture' : 'chanting';
                            form[form.bookType] = buddhism[form.bookType];
                        } else if(info.fs_type == 3) {
                            form.number = buddhism.number;
                        }
                    }
                    try {
                        form.tabletInfo = buddhism.tablet_info ? JSON.parse(buddhism.tablet_info) : [];
                        form.tabletInfo.forEach(item => {
                            if(!item.area) {
                                item.area = [...(form.area || [])];
                                item.address = form.address;
                                item.username = form.username;
                                item.contact_person = form.contact_person;
                                item.tel = form.tel;
                                item.commit_note = form.commit_note;
                            }
                        })
                    } catch (e) {
                        console.error(e);
                    }
                    this.form = form;
                    if(info.fs_type == 2) {
                        this.handleChantingChange();
                    }
                } else if (this.info.cat === 1 && !this.relation) {
                    this.form = {
                        ...this.form,
                        user_type: 1
                    }
                }
            },
            cancel() {
                this.$emit("cancel");
            },
            validateFormOther() {
                return new Promise((resolve, reject) => {
                    this.$refs.buddhismForm.validate((valid, obj) => {
                        if (valid) {
                            resolve();
                        } else {
                            let msg = "form error";
                            // 返回第一个表单的错误信息
                            const keys = Object.keys(obj);
                            if (keys.length > 0) {
                                const k = keys[0];
                                const arr = obj[k];
                                if (arr[0]?.message) {
                                    msg = arr[0].message;
                                }
                            }
                            reject(msg);
                        }
                    });
                })
            },
            submit() {
                if(this.loading) return;
                Promise.all([this.validateTabletInfo(), this.validateFormOther()]).then(() => {
                    this.loading = true;
                    let form = this.form;
                    form.tabletInfo.forEach(t => {
                        if (t.personList?.length > 0) {
                            t.personList = t.personList.filter(p => p.flag).map(p => omit(p, ['flag']));
                        }
                        if (t.posterityList?.length > 0) {
                            t.posterityList = t.posterityList.filter(p => p.flag).map(p => omit(p, ['flag']));
                        }
                    })
                    if(this.buddhism && form.dateTime.range != this.buddhism.time_range) {
                        this.$message.warning("暂不支持修改预约时间段，请重新选择！");
                        return;
                    }
                    let detail = {
                        area: form.area,
                        address: form.address
                    }
                    if(form.bill) {
                        form.bill_img = JSON.stringify(form.bill.split(","));
                    }
                    if(form.dateTime) {
                        form.date = form.dateTime.date;
                        form.time_range = form.dateTime.range;
                    }
                    if(this.info.fs_type == 2) {
                        detail.bookType = form.bookType;
                        const extraNumber = form.extraNumber || 0;
                        const options = this.info.chantingOptions[form.bookType];
                        let number = options.find(o => o.value == form[form.bookType]).number;
                        const max = this.numberConfig[form.type].max;
                        if(max && number + extraNumber > max) {
                            this.$message.warning(`诵经人数最多${max}人`);
                            return;
                        }
                        form.number = detail.number = number;
                        detail[form.bookType] = form[form.bookType];
                        if(form.extraNumber) {
                            detail.extraNumber = form.extraNumber
                        }
                    } else if(this.info.fs_type == 3) {
                        detail.number = form.number;
                    }
                    const {price} = calculateBuddhismPrice(this.info, form);
                    form.price = price;
                    form.tablet_info = form.tabletInfo ? JSON.stringify(form.tabletInfo) : null;
                    form.detail = JSON.stringify(detail);
                    let url = "/admin/fs";
                    let method = "POST";
                    if(form.id) {
                        url += `/${form.id}`;
                        method = 'PATCH';
                    } else {
                        form.admin_id = getUserWxId();
                        form.temp_id = this.info.id;
                        // 历史数据标志位
                        if(this.history) {
                            form.status = 6;
                            form.is_history = 1;
                            form.user_id = getUserWxId();
                        }
                        // 法会登记的佛事不需要认领
                        else if(this.info.cat === 3) {
                            form.status = 4;
                        }
                    }
                    // console.log(form, url, method);
                    this.$axios({
                        url,
                        method,
                        data: form
                    }).then(res => {
                        this.$emit("update", res);
                    }).finally(() => {
                        this.loading = false;
                    });
                }).catch((err) => {
                    this.$message.warning(err || '登记信息填写有误');
                });
            },
            handleSubTypeChange() {
                this.$set(this.form, 'date', undefined);
                this.$set(this.form, 'tabletInfo', undefined);
                if (this.buddha.fs_type == 3) {
                    const type = this.form.type;
                    const chantingNumbers = this.info.chantingNumbers;
                    let number = undefined;
                    if (chantingNumbers) {
                        if (type == '07'  && chantingNumbers.oneNumber?.length > 0) {
                            number = chantingNumbers.oneNumber[0];
                        } else if (type == '08' && chantingNumbers.threeNumber?.length > 0) {
                            number = chantingNumbers.threeNumber[0];
                        }
                    }
                    this.$set(this.form, 'number', number);
                }
            },
            handleBookTypeChange(val) {
                const options = this.info.chantingOptions?.[val] || [];
                this.$set(this.form, val, options[0]?.value);
                this.$set(this.form, val == 'scripture' ? 'chanting' : 'scripture', undefined);
            }
        }
    }
</script>

<style scoped lang="less">
  .buddhism-form-content {
    padding: 0 24px;
  }
  .buddhism-form-box {

  }
  .book-add {
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    right: -8px;
    margin: auto 0;
    transform: translateX(100%);
    width: 1em;
    height: 1em;
    font-size: 24px;
    line-height: 1;
    cursor: pointer;
    color: @primary-color;
    z-index: 1;
  }
</style>

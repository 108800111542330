<template>
    <a-dropdown :trigger="['click']" v-model="visible">
        <div class="lunar-date">
            <div class="lunar-date-show">
                <div class="lunar-date-text" v-if="showDate">{{ showDate }}</div>
                <div class="lunar-date-text lunar-date-place" v-else>{{ placeholder || '请选择日期'}}</div>
                <a-icon type="calendar" class="lunar-date-icon" />
            </div>
        </div>
        <div class="lunar-calendar-box" slot="overlay">
            <a-calendar
                v-model="current"
                @select="handleSelect"
                :fullscreen="false"
                :header-render="headerRender"
                :disabledDate="disabledDate">
                <template v-slot:dateFullCellRender="date">
                    <div class="lunar-calendar-date" :class="{selected: date.format('YYYY-MM-DD') == current?.format('YYYY-MM-DD'), disabled: disabledDate && disabledDate(date)}">
                        <div class="calendar-date-num">{{date.date()}}</div>
                        <div class="calendar-date-lunar">{{getLunarCellDate(date)}}</div>
                    </div>
                </template>
            </a-calendar>
        </div>
    </a-dropdown>
</template>

<script>
    import moment from 'moment';
    import { HolidayUtil, Lunar } from 'lunar-javascript';

    export default {
        name: "lunar-picker",
        model: {
            event: "change"
        },
        props: {
            value: String,
            placeholder: String,
            disabledDate: Function,
        },
        data() {
            return {
                visible: false,
            }
        },
        computed: {
            current: {
                get() {
                    return this.value ? moment(this.value) : undefined;
                },
                set(val) {
                    this.$emit("change", val ? val.format("YYYY-MM-DD") : undefined);
                }
            },
            showDate() {
                const date = this.current;
                if (date) {
                    const d = Lunar.fromDate(date.toDate());
                    return `${d.getYear()}年${d.getMonthInChinese()}月${d.getDayInChinese()}`
                }
                return "";
            },
            yearOptions() {
                const options = [];
                const y = new Date().getFullYear();
                for (let i = y - 200; i < y + 1; i += 1) {
                    options.push({
                        label: this.getYearLabel(i),
                        value: String(i),
                    });
                }
                return options;
            }
        },
        methods: {
            handleSelect() {
                this.visible = false;
            },
            getYearLabel(year) {
                const d = Lunar.fromDate(new Date(year + 1, 0));
                return `${d.getYear()}年（${d.getYearInGanZhi()}${d.getYearShengXiao()}年）`;
            },
            getMonthLabel(month, value) {
                const d = Lunar.fromDate(new Date(value.year(), month));
                const lunar = d.getMonthInChinese();
                return `${month + 1}月（${lunar}月）`;
            },
            getLunarCellDate(date) {
                const d = Lunar.fromDate(date.toDate());
                const lunar = d.getDayInChinese();
                const solarTerm = d.getJieQi();
                const holiday = HolidayUtil.getHoliday(date.get('year'), date.get('month') + 1, date.get('date'));
                const displayHoliday = holiday?.getTarget() === holiday?.getDay() ? holiday?.getName() : undefined;
                return displayHoliday || solarTerm || lunar;
            },
            headerRender({ value, onChange }) {
                const start = 0;
                const end = 12;
                const monthOptions = [];

                let current = value.clone();
                const localeData = value.localeData();
                const months = [];
                for (let i = 0; i < 12; i++) {
                    current = current.month(i);
                    months.push(localeData.monthsShort(current));
                }

                for (let i = start; i < end; i++) {
                    monthOptions.push({
                        label: this.getMonthLabel(i, value),
                        value: String(i),
                    });
                }
                const year = value.year();
                const month = value.month();
                return (
                  <div class="flex-box" style={{ padding: '10px', gap: '8px' }} onClick={e => e.stopPropagation()}>
                      <div class="flex-grow">
                          <a-select
                            size="small"
                            dropdownMatchSelectWidth={false}
                            options={this.yearOptions}
                            onChange={newYear => {
                                const now = value.clone().year(newYear);
                                onChange(now);
                            }}
                            value={String(year)}
                            style="width: 100%"
                          >
                          </a-select>
                      </div>
                      <div class="width: 40%">
                          <a-select
                            size="small"
                            dropdownMatchSelectWidth={false}
                            value={String(month)}
                            onChange={selectedMonth => {
                                const newValue = value.clone();
                                newValue.month(parseInt(selectedMonth, 10));
                                onChange(newValue);
                            }}
                            options={monthOptions}
                            style="width: 100%"
                          >
                          </a-select>
                      </div>
                  </div>
                );
            },
        }
    }
</script>

<style scoped lang="less">
    .lunar-calendar-box {
        width: 340px;
        border: 1px solid #d9d9d9;
        border-radius: 4px;
        background-color: @component-background;
    }
    .lunar-date-show {
        display: inline-flex;
        align-items: center;
        position: relative;
        padding: 0 11px;
        width: 100%;
        height: 32px;
        border-radius: 0 4px 4px 0;
        border: 1px solid @normal-color;
        line-height: 1.5;
        cursor: pointer;
        &:hover {
            border-color: @primary-5;
        }
        .lunar-date-icon {
            position: absolute;
            top: 0;
            right: 10px;
            bottom: 0;
            margin: auto 0;
            width: 14px;
            height: 14px;
            font-size: 14px;
            line-height: 1;
            color: rgba(0, 0, 0, 0.25);
        }
    }
    .lunar-date-text {
        flex: 1;
        min-width: 0;
        white-space: nowrap;
    }
    .lunar-date-place {
        color: #bfbfbf;
    }
    .lunar-calendar-date {
        font-size: 12px;
        cursor: pointer;
        text-align: center;
        line-height: 1;
        .calendar-date-num {
            font-size: 16px;
        }
        .calendar-date-lunar {
            margin-top: 2px;
            color: @text-color-secondary;
        }
        &.selected {
            background-color: #bae7ff;
        }
        &.disabled {
            color: @disabled-color;
            cursor: not-allowed;
            .calendar-date-lunar {
                color: @disabled-color;
            }
        }
    }
</style>
